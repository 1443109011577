<template>
  <div class="search-c">
    <img
      src="../../assets/lupa.svg"
      alt="search"
      class="cp"
      @click="clicked = true"
      v-if="clicked == false"
    />
    <div class="search-form dflx aic" :class="{'act':clicked}">
      <p @click="clicked = false" v-if="clicked == true" class="cp">x</p>
      <input
        type="text"
        name="search"
        id="search"
        placeholder="Buscar"
        v-model="search"
        @keyup.enter="submit(search)"
      />
      <a :href="'/result/'+search"><img src="../../assets/lupa.svg" alt="search" class="cp" /></a>      
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      clicked: false,
      search: "",
    };
  },
  methods: {
    submit(value) {
      return window.location.replace("/result/" + value);
    },
  },
};
</script>
<style>
.search-c {
  font-family: var(--Sans);
}
.search-c {
  width: 100%;
  height: 11.836vw;
}
.search-c input {
  background: transparent;
  border: none;
  border-right: 0.2vw solid gray;
  color: white;
  padding: 4.831vw;
  width: 100%;
}
.search-c img {
  display: none;
}
.search-c input:focus-visible {
    outline: none;
}
@media (min-width: 768px) {
  .search-c img {
    display: block;
    margin-left: 1vw;
  }
  .search-c input {
    background: transparent;
    border: none;
    border-bottom: 0.2vw solid gray;
    color: gray;
    padding: 0.831vw;
  }
  .search-c {
    display: flex;
    height: 2.836vw;
    align-items: center;
  }
  .search-c input {
    width: 100%;
    height: 2.836vw;
  }
  .search-c img {
    width: 0.8vw;
    height: 0.8vw;
    display: block;
  }
  .search-c .search-form {
    width: 0;
    overflow: hidden;
    transition: 0.5s;
  }
  .search-c .search-form.act {
    width: 21vw;
  }
}
</style>