<template>
  <div class="Navigator">
    <div class="col1">
      <div class="sm-cont">
        <a
          href="https://www.youtube.com/channel/UCyD3mhDSgYCQlUfr9Kb6owg?view_as=subscriber"
          class="sm-i"
          target="_blank"
          rel="noopener noreferrer"
          ><img src="../../assets/iyt.png" alt="yt"
        /></a>
        <a
          href="https://www.instagram.com/revistafoodie/"
          class="sm-i"
          target="_blank"
          rel="noopener noreferrer"
          ><img src="../../assets/iig.png" alt="ig"
        /></a>
        <a
          href="https://www.facebook.com/revistafoodie"
          class="sm-i"
          target="_blank"
          rel="noopener noreferrer"
          ><img src="../../assets/ifb.png" alt="fb"
        /></a>
        <a
          href="https://api.whatsapp.com/send?phone=5216629488430&text=%C2%A1Hola,%20Revista%20Foodie!"
          class="sm-i"
          target="_blank"
          rel="noopener noreferrer"
          ><img src="../../assets/iwh.png" alt="wa"
        /></a>
        <a href="mailto:contacto@revistafoodie.com"
          >contacto@revistafoodie.com</a
        >
      </div>
      <div class="banner"
          v-if="banner !== 'No se encontraron coincidencias.'">
        <a
          :href="banner.link" target="_blank" rel="noopener noreferrer"
         
          ><img
            :src="
              urlpath.url() +
              '/get-banner-image/' +
              banner.image
            "
            alt="banner"
        /></a>        
      </div>

      <div v-else></div>
    </div>
   
    <div class="col2 dflx jcsb aic w100">
     
      <div class="nav-m">
        <a href="/">INICIO</a>
        <a href="/conocenos">CONÓCENOS</a>
        <a href="/colaboradores">COLABORADORES</a>
      </div>
      <a href="/" class="logo-pr">
        <img src="../../assets/foodie-l.png" alt="" />
      </a>
      <div class="icon-m cp" @click="activem = true">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
      <div class="nav-m">
        <a href="/blog">BLOG</a>
        <!--<a href="">TIENDA</a>-->
        <Search />
        <!--<a href="">
            <img src="" alt="" />
        </a>-->
      </div>
    </div>
    <div class="banner-mv"
          v-if="banner !== 'No se encontraron coincidencias.'">
        <a
          :href="banner.link" target="_blank" rel="noopener noreferrer"
         
          ><img
            :src="
              urlpath.url() +
              '/get-banner-image/' +
              banner.image
            "
            alt="banner"
        /></a>        
      </div>
    <div
      class="scroll-menu-c"
      :class="{ nac: activem }"
      @click="activem = false"
    >
      <div class="s-menu" :class="{ nac: activem }" @click.stop="">
        <div class="c-1">
          <Search />
          <p class="cp" @click="activem = false">x</p>
        </div>
        <div class="c-2">
          <a href="/">INICIO</a>
          <a href="/colaboradores">COLABORADORES</a>
          <a href="/conocenos">CONÓCENOS</a>
          <a href="/blog">BLOG</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
let urlpath = require("../../global/url");
import Search from "../items-page/Search.vue";
export default {
  data() {
    return {
      activem: false,
      urlpath,
    };
  },
  components: {
    Search,
  },
  created() {
    this.getAllInfoBnr2("banner");
  },
  computed: {
    banner() {
      let arr = this.$store.getters["banner/data2"];
      if (arr == "No se encontraron coincidencias.") {
        return "No se encontraron coincidencias.";
      } else {
        let array = arr.filter((arr) => arr.section == "navegador");
        return array[0];
      }
    },
  },
  methods: {
    ...mapActions("banner", ["getAllInfoBnr2"]),
  },
};
</script>
<style>
.Navigator {
  font-family: var(--Sans);
}
.col1 {
  display: none;
}
.banner-mv img{
  width: 100%;
}
.col2 {
  padding: 9.662vw 0;
  width: 92.754vw;
  margin: auto;
}
.col2 .nav-m {
  display: none;
}
.col2 .logo-pr img {
  width: 50vw;
  height: auto;
}

/**NAV */
.scroll-menu-c {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  transition: 0.5s;
  background: #00000075;
  overflow: hidden;
  z-index: 1;
}
.scroll-menu-c.nac {
  opacity: 1;
  pointer-events: all;
}
.s-menu {
  width: 67.633vw;
  height: 100vh;
  background: #252525;
  position: relative;
  margin-left: auto;
  margin-right: -77.295vw;
  transition: 1s;
  z-index: 99;
}
.s-menu.nac {
  margin-right: 0;
}
.s-menu .c-1 {
  display: flex;
  align-items: center;
  height: 11.836vw;
  width: 100%;
  background: #131313 !important;
  border-bottom: 0.2vw solid gray;
}
.s-menu .c-1 p {
  font-size: 4.831vw;
  display: block;
  color: #fff;
  padding: 3.14vw;
  margin: 0;
  width: 5.797vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}
.s-menu .c-2 {
  display: flex;
  flex-direction: column;
}
.s-menu .c-2 a {
  padding: 2.415vw 4.831vw;
  border-top: 0.242vw solid #333;
  border-bottom: 0.242vw solid #333;
  color: #fff;
  transition: 0.4s;
}
.s-menu .c-2 a:hover {
  background: #333 !important;
}
/**icon */
.icon-m {
  width: 7.246vw;
  height: 6.246vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 8.454vw;
}
.icon-m .bar {
  background-color: black;
  height: 1.2vw;
  border-radius: 0.4vw;
  width: 100%;
  margin: 0;
  transition: 0.5s;
}
.icon-m:hover .bar {
  background-color: rgb(173 173 173);
}
@media (min-width: 768px) {
  .banner-mv{
  display: none;
}
  .col1 {
    width: 95vw;
    border-bottom: 0.15vw solid #ccc;
    margin: auto;
    padding: 1.042vw 0;
    display: flex;
    justify-content: space-between;
  }
  .col1 .sm-cont {
    width: auto;
    height: 1.667vw;
    display: flex;
    align-items: center;
  }
  .col1 .sm-cont a.sm-i {
    width: 1.667vw;
    height: 1.667vw;
    display: flex;
    margin-right: .3vw;
  }
  .col1 .sm-cont a.sm-i img {
    width: 1.667vw;
    height: 1.667vw;
  }
  .col1 .sm-cont a {
    font-size: 1vw;
    margin: 0;
    color: black;
  }
  .col1 .sm-cont a:last-child {
    margin-left: 2.083vw;
    font-weight: 700;
  }
  .col1 .banner {
    width: 37.917vw;
    height: 4.688vw;
    background: gainsboro;
  }
  .col1 .banner img {
    width: 100%;
  }
  .col2 {
    padding: 2.083vw 0;
    width: 92.754vw;
    margin: auto;
  }
  .col2 .logo-pr img {
    width: 12.604vw;
    height: auto;
  }
  .icon-m {
    display: none;
  }
  .col2 .nav-m {
    display: flex;
    align-items: center;
  }
  .col2 .nav-m a {
    margin-right: 1.042vw;
    font-weight: 500;
    color: black;
    font-family: var(--RedHat);
    transition: 0.5s;
    font-size: 1.2vw;
  }
  .col2 .nav-m a:hover {
    color: gray;
  }
  .col2.jcsb {
    justify-content: flex-start;
  }
  .col2 .logo-pr {
    margin: 0 20% 0vw 10%;
  }
}
</style>